<template>
    <div class="error-page">
        <h1>
            Error : {{ error.statusCode }}
        </h1>
        <p>{{ error.message }}</p> <br />
        <p v-if="isDev">{{ error.stack }}</p> <br /><br />
        <NuxtLink :to="localePath({name: 'app'})">{{ homePageBtnText }}</NuxtLink>
    </div>
</template>

<script setup lang="ts">
    import type { NuxtError } from '#app';
    import { type GlobalResources as Gr } from "i18n-resources";

    //#region Data
    const isDev = import.meta.env.DEV;
    const localePath = useLocalePath();
    const { t } = useELocale();
    //#endregion

    //#region Computed
    const homePageBtnText = computed(() => t<Gr>(gr => gr.homePage));
    //#endregion

    //#region Props
    const props = defineProps({
        error: {
            type: Object as PropType<NuxtError>,
            default: null
        }
    });
    //#endregion
</script>

<style scoped>
    h1 {
        font-size: 20px;
    }

    .error-page a {
        color: white;
    }
</style>
